<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
    <!-- <dashboard-core-settings /> -->
    <dashboard-core-footer />
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",

  created: async function () {
    try {
      await this.$store.dispatch("refreshUser");
    } catch (error) {
      if (error.response?.status === 401) {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    // DashboardCoreSettings: () => import('./components/core/Settings'),
    DashboardCoreView: () => import("./components/core/View"),
    DashboardCoreFooter: () => import("./components/core/Footer"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
